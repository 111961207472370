import React from 'react'

import MainLayout from '../../layouts/mainlayout/MainLayout'
import PageHeader from '../../components/pageheader/PageHeader'
import SeniorPastor from '../../components/about/pastoralteam/SeniorPastor'
import Pastors from '../../components/about/pastoralteam/Pastors'

const PastoralTeam = () => {
	return (
		<MainLayout title="About Us | Pastoral Team">
			<PageHeader title="About Us" subnav="about" />
			<SeniorPastor />
			<Pastors />
		</MainLayout>
	)
}

export default PastoralTeam