import React from 'react'

const SeniorPastor = () => {
	return (
		<section className='about-section'>
			<h3 className='section-head'>Our Senior Pastor </h3>
			<p>
				There was a little boy, named Johnson in a small village in India. He was born and brought up in a Hindu family. Even at the age of 13, he had a longing to see God in his heart. He used to go to a nearby library and read Hindu legends to know more about gods. As he went through the legends, his notion about gods changed drastically for he understood that those were just myths.
			</p>
			<p>
				Later he turned to Atheism and indulged in a sinful life. He had no contentment in his heart and lost his peace of mind. He was gripped with fear of the future.
				On a fine day, one of his family friends invited him to a Christmas service at a church in a nearby town. Even though he went there with much hesitation, the Lord touched him and changed his life. He accepted Jesus Christ and was baptized on 3rd September 1989. He then joined a granite company as a machine operator.
			</p>
			<p>
				One fine evening, while he was praying, God intervened miraculously and encountered him. The Lord Jesus spoke to his heart saying. <strong>"You are having only one life and It passes away like a tale that is told. Spend it in my service and I will give you an eternal crown."</strong> Johnson cried out for an hour before God and finally committed his life at his feet. He resigned from his job and moved to Bangalore to pursue his studies at Southern Asia Bible College, as a minister of God in 1994. In 1997 he received his Bachelor of Theology Degree. After graduating, he assisted Rev. Jaipal, Living Gospel Fellowship, Bangalore and then Rev. John Jebaraj, Praise Assembly, Chennai.
			</p>
			<p>
				He married Jemimah in 1998. And after a few months, they moved to Thiruverkadu to pioneer a church. God since blessed their home with a beautiful boy Jerry Jemson, and a girl Jerusha Pearlin.
			</p>
			<p>
				Now, Rev. Johnson has completed his Masters in Theology and is pursuing his Doctorate in Theology. God is using him mightily for his mission and Rev. Johnson is a blessing to many people in and around Thiruverkadu.
			</p>
		</section>
	)
}

export default SeniorPastor