import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import "./pastoralteam.scss";

const Pastors = () => {
	const data = useStaticQuery(graphql`
	  query PastoralQuery {
		allMongodbFlagchurchPastors(sort: {fields: order, order: ASC}) {
		  pastors: nodes {
			bio
			name
			title
			order
		  }
		}
	  }			
	`);

	const pastors = data.allMongodbFlagchurchPastors.pastors;

	return (
		<section className='about-section'>
			<h3 className="section-head mb-3">Our Pastors</h3>
			<div className='row'>
				{
					pastors.map((pastor, index) => {
						return (
							<div className="col-12 col-sm-12 col-md-4 col-lg-3 mb-4" key={index}>
								<div className="pastor-card">
									{
										(false) ? (
											<div className="image">
												{/* <img src={pastor.image} alt="nope" /> */}
												<div
													className="image-backdrop"
												>
													{/* <img src={pastor.image} alt="nope" /> */}
												</div>
											</div>
										) : ""
									}
									<div className="pastor-info">
										<h4>{pastor.name}</h4>
										<h5>{pastor.title}</h5>
										<div dangerouslySetInnerHTML={{ __html: pastor.bio }} />
									</div>
								</div>
							</div>
						)
					})
				}

			</div>
		</section>
	)
}

export default Pastors